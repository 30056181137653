.section-two-wrapper {
  text-align: center;
}

.section-two-wrapper .btn-violet-outline-r {
  color: var(--color-white) !important;
  border: 1px solid var(--color-white) !important;
}

.section-two-wrapper .header {
  color: var(--color-black);
  letter-spacing: -1px !important;
}

.section-two-wrapper .lead {
  /* color: var(--color-white) !important; */
}

.section-two-wrapper .steps-header {
  color: var(--color-black);
  letter-spacing: -1px !important;
}

.section-two-wrapper .steps-body {
  color: var(--color-black);
  line-height: var(--spacing-24);
}

.section-two-wrapper .box {
  border-radius: var(--spacing-48);
}

.hr-violet {
  background-color: var(--color-violet-400);
}

.hr-white {
  background-color: white;
}

@media (max-width: 576px) {
  .section-two-wrapper .steps-body {
    font-size: 18px;
    line-height: var(--spacing-32);
  }
}
