.home-wrapper {
  background-color: var(--color-gray-100);
}

.tp-icon {
  /* margin-top: -5px !important; */
}

.card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
