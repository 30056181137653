.footer {
  padding-top: var(--spacing-48);
  padding-bottom: var(--spacing-80);
  background-color: var(--color-black-400);
  color: var(--color-white);
}

.footer h2.row-header {
  color: var(--color-violet-400);
}

.footer h5.row-header {
  letter-spacing: -0.5px;
}

.footer .row-list {
  padding: 0 !important;
  list-style-type: none;
  line-height: var(--spacing-32);
  font-weight: 600;
}
