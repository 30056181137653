.about-us-wrapper {
  margin-top: var(--spacing-128);
  margin-bottom: var(--spacing-80);
}

.about-us-wrapper .pp-header {
  color: var(--color-violet-700);
}

.about-us-wrapper .img-nmc-logo {
  width: 350px;
  object-fit: contain;
}

.about-us-wrapper .img-medoc-logo {
  width: 100px;
}
