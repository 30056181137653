.products-wrapper {
  margin-top: var(--spacing-32);
  padding-top: var(--spacing-128);
  background-color: var(--color-gray-100);
}

.products-wrapper .blog-img {
  border-radius: var(--spacing-32);
  width: 100% !important;
  object-fit: cover;
  max-height: 17rem !important;
  object-fit: cover;
}
