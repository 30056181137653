.doctor-profile-wrapper .profile-photo {
  width: var(--spacing-160);
  height: var(--spacing-160);
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--color-violet-400);
  padding: var(--spacing-4);
}

.doctor-profile-wrapper .card {
  box-shadow: 0px 2px 10px 5px rgba(228, 215, 255, 0.25) !important;
}

.doctor-profile-wrapper .time-slot-wrapper {
  max-height: var(--spacing-80);
  overflow: scroll;
}

.achievements .card {
  border-width: 0px;
}
