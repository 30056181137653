.locations-wrapper {
  background-color: var(--color-gray-100);
  height: 100vh;
}

.locations-wrapper .search-box {
  margin: var(--spacing-48);
  text-align: center;
  border-radius: var(--spacing-24);
}
