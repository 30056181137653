.card-product {
  background: var(--color-white);
  border: 0px solid var(--color-violet-100);
  border-radius: var(--spacing-12);
}

.card-product .card {
  border: 0px solid #fff;
  padding: var(--spacing-32) var(--spacing-32);
  background-color: var(--color-white);
  border-radius: var(--spacing-24);
}

.card-product .card .list-group {
  list-style-type: none !important;
}
