.work-wrapper {
  padding: var(--spacing-48) var(--spacing-12);
  border-radius: var(--spacing-24);
}

.work-wrapper .card {
  border: 0;
  padding: 0;
  border-top-right-radius: var(--spacing-24) !important;
  border-top-left-radius: var(--spacing-24) !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.work-wrapper .card .card-header {
  position: relative;
}

.work-wrapper .card .step-text {
  position: absolute;
  top: 4%;
  left: 3%;
}

.work-wrapper .card img {
  border-top-left-radius: var(--spacing-24) !important;
  border-top-right-radius: var(--spacing-24) !important;
  max-height: 90vh !important;
}

.work-wrapper .card-body {
  margin-top: var(--spacing-12);
}
