.section-one-wrapper {
  padding-top: var(--spacing-64);
  background-color: var(--color-white);
  color: var(--color-violet-400);
}

.section-one-wrapper ul {
  list-style: none;
}

.section-one-wrapper ul i.fa {
  /* color: var(--color-violet-400); */
}

.si-logo {
  width: var(--spacing-160);
  /* height: var(--spacing-64); */
}

.hl-logo {
  width: var(--spacing-128);
}

@media (max-width: 576px) {
  .section-one-wrapper {
    padding-top: var(--spacing-64);
  }

  .home-image-1 {
    /* padding: var(--spacing-64); */
    transform: scale(1.6);
  }
}

@media (min-width: 577px) {
  .home-image-1 {
    /* padding-top: var(--spacing-96);
    padding-bottom: var(--spacing-80); */
    transform: scale(1.1);
  }
}
