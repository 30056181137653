@import "assets/css/common.css";
@import "assets/css/headers.css";
@import "assets/css/modal.css";

* {
  /* font-family: var(--font-family-display); */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: "Poppins", sans-serif; */
  font-family: "Quicksand", sans-serif;
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.App {
  font-family: sans-serif;
  /*text-align: center;*/
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.fa {
  cursor: pointer;
}

.font-small {
  font-size: small !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.letter-spacing-2 {
  letter-spacing: 0.05em;
}

.white-space-preline {
  white-space: pre-line;
}

.card-br-0 {
  border-radius: 0px !important;
}

.br-0 {
  border-radius: 0px !important;
}

.text-grey {
  color: #7f8fa6;
}

.text-native {
  color: rgba(220, 53, 69, 1) !important;
}

.text-default {
  color: #000000 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.decoration-none {
  text-decoration: none !important;
}

.page-top-alert {
  position: absolute;
  /*bottom: 50px;*/
  top: 0px;
  left: 42vw;
  margin: 0px auto;
  z-index: 99999;
  background-color: #dc3545;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
}

.fw-900 {
  font-weight: 900 !important;
}

/* Sort options */
.sort-options {
  position: relative;
}

.sort-btn {
  color: #fff;
  position: fixed;
  bottom: 70px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  right: 3vw;
  background-color: #dc3545;
  z-index: 999;
}

.sort-btn i {
  margin-top: 12px;
}

.sort-menu {
  background-color: #fff;
  position: fixed;
  bottom: 120px;
  right: 3vw;
  padding: 10px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 13px;
  z-index: 1200;
  box-shadow: 0 0 1px 1px rgba(50, 50, 50, 0.1);
}

/* Audio controls */
audio {
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  height: 45px;
  outline: none !important;
}

audio::-internal-media-controls-download-button {
  display: none;
}

/* Radio group */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  /* color: #666; */
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 18px;
  height: 18px;
  border: 1px solid #a1a1a1;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  /* background: rgba(220, 53, 69, 0.8); */
  background: var(--color-violet-400);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

input[type="checkbox"]:checked {
  accent-color: var(--color-violet-400);
}

.text-between-line:before,
.text-between-line:after {
  content: "\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
}

.badge {
  vertical-align: middle !important;
  font-size: x-small !important;
  /* border-radius: 50% !important; */
}

.blur-image {
  filter: blur(4px) !important;
}

input.form-input {
  /*max-width: 450px;*/
  width: auto;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /*transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);*/
  padding: 10px;
}

input[type="date"] {
  min-width: 95%;
}

.btn-sm-app {
  /*outline: none;*/
  color: #fff !important;
  min-width: 100px !important;
  font-size: 16px !important;
  padding: 5px 7px !important;
  background-color: #dc3545 !important;
}

button.login {
  width: 125px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  padding: 7px;
  text-align: center;
  cursor: pointer;
}

button.login[type="submit"] {
  background-color: #4c566a;
  color: white;
  font-size: 14px;
  font-weight: 900;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 15px;
}

button:focus,
button:active {
  box-shadow: none !important;
  outline: none !important;
  background-color: initial;
}

.googleBtn {
  width: 225px;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.googleBtn img {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 5px;
  vertical-align: middle;
}

/* Signup page */
.signup-form {
  font-size: 13px;
}

.signup-form input {
  border-radius: 0px;
  font-size: 13px;
  box-shadow: none !important;
}

.signup-form .input-password {
  position: relative;
}

.signup-form .input-password .show-password {
  position: absolute;
  top: 36px;
  right: 10px;
  font-size: 17px;
}

.signup-form .btn-submit {
  border: 1px solid;
  border-radius: 0px;
  min-height: 7vh;
  min-width: 35vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.signup-form a {
  font-size: 14px;
}

.nav {
  display: flex;
  text-decoration: none;
  list-style-type: none;
  justify-content: flex-start;
  padding: 0;
}

.nav li {
  margin: 0 10px;
}

.beta-badge {
  border-radius: 3px !important;
  letter-spacing: 0.5px !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

.count-badge {
  position: relative;
  top: -5px;
}

/* Profile page */
.profile-pic-section {
  position: relative;
  cursor: pointer;
}

.profilePic {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s;
}

.profile-pic-section .hover-text {
  display: none;
  position: absolute;
  top: 53px;
  left: 18px;
  color: #000;
  transition: all 0.3s;
}

.profile-pic-section:hover .profilePic {
  opacity: 0.1;
  border: 1px solid rgba(0, 0, 0, 0.7);
}

.profile-pic-section:hover .hover-text {
  display: inline;
}

.profile-options {
  overflow-x: auto;
  text-align: center;
  overflow-y: hidden;
}

.profile-options::-webkit-scrollbar {
  display: none;
}

.profile-options div.option {
  font-size: 13px;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

.profile-options div.option div:after {
  content: "\b7";
  font-size: 34px;
  color: #dc3545;
}

.profile-options div.option .active {
  margin-top: -21px !important;
  height: 33px;
}

.profile-options div.option:first-child {
  /*padding-left: 0.5rem;*/
}

/* Profile: Permissions */
.permissions-wrapper {
  /*text-align: center;*/
  font-size: 13px;
  /* margin-left: 12px; */
}

/* Profile: Settings page */
.change-password-form {
  padding: 10px;
  font-size: 13px;
  position: relative;
}

.change-password-form .show-password {
  position: absolute;
  font-size: 18px;
  right: 22px;
  top: 48px;
}

/* Notification */
.notification {
  position: fixed !important;
  bottom: 20px;
  left: 20px;
  max-width: 300px;
  z-index: 9999;
}

.fadeOut {
  animation-name: fadeOut;
  -webkit-animation-name: fadeOut;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.icon-bg-dark {
  cursor: pointer;
  font-size: 21px;
  border-radius: 50%;
  position: relative;
  left: -10px;
  top: 48px;
}

.cookie-banner {
  font-size: var(--font-size);
  position: fixed;
  bottom: 0px;
  padding: 0.4em 0.9em 1.4em;
  border: 1px solid #eee;
  align-items: center;
  background-color: #fff;
  z-index: 999;
}

/* Default Layout */
.navbar {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding-right: 0.6rem !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.hidden-spacer {
  height: 56px;
}

.nav-link a {
  /*color: #DC3545 !important;*/
}

.navbar-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.navbar-nav {
  text-align: center;
}

.navbar-nav .nav-item a {
  /* padding: 0 0.6rem; */
  transition: all 0.35s;
}

.navbar-nav .nav-support,
.navbar-nav .nav-support a {
  color: #fff !important;
  background-color: rgba(220, 53, 69, 1);
  /*background-color: #6C757D;*/
  padding-top: 4px;
}

.navbar-nav .nav-item:hover {
  /*background-color: rgba(220, 53, 69, 1);*/
  cursor: pointer;
}

.navbar-nav .nav-item:hover a,
.navbar-nav .nav-item:hover a:hover {
  /*color: #fff !important;*/
  text-decoration: none;
}

.navbar-nav .nav-item-active {
  border-bottom: 2px solid #6c757d;
  color: red;
}

.navbar-nav .nav-profile:hover {
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

/* Home */
.post-textbox,
.post-textbox:focus,
.post-textbox:active {
  width: 100%;
  border: 3px solid #fff;
  border-radius: 0.4em;
  outline: none;
  resize: none;
  padding: 10px;
}

.create-post > .input-group > .input-group-prepend > .input-group-text {
  background-color: #fff !important;
  border-radius: 0px;
}

.create-post-card {
  border-radius: 0px !important;
}

.create-post-card-type {
  cursor: pointer;
  border: 0px solid #fff !important;
  border-radius: 0px !important;
  color: #6c757d !important;
}

.continue-posts .left-btn,
.continue-posts .right-btn {
  font-size: larger;
}

.post-menu-dropdown {
  position: static !important;
}

.post-menu-icon {
  font-size: x-large;
}

.post-menu-dropdown .dropdown-menu {
  padding: 0px !important;
  font-size: small !important;
  min-width: 5rem !important;
  left: -10px !important;
}

/* Home page - Desktop */
.left-sidebar-wrapper {
  position: fixed !important;
  min-width: 11rem !important;
}

.left-sidebar-wrapper .card-body {
  padding: 20px 0px !important;
}

.left-sidebar-wrapper a,
.left-sidebar-wrapper a:hover,
.left-sidebar-wrapper a:focus,
.left-sidebar-wrapper a:active,
.alerts-wrapper a,
.alerts-wrapper a:hover,
.alerts-wrapper a:focus,
.alerts-wrapper a:active {
  color: #000000 !important;
  text-decoration: none !important;
}

.left-sidebar-wrapper .profile-details {
  text-align: center;
  cursor: pointer;
}

.left-sidebar-wrapper .profile-pic {
  height: 57px;
  width: 57px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.left-sidebar-wrapper .profile-name {
  font-size: 13px;
  margin-top: 10px;
  text-decoration: none !important;
}

.left-sidebar-wrapper .create-post {
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.right-sidebar-wrapper {
  position: fixed !important;
  width: 16rem !important;
}

.right-sidebar-wrapper .card-body {
  font-size: 13px;
  padding: 20px 0px 0px !important;
  text-align: justify;
}

.right-sidebar-wrapper a,
.right-sidebar-wrapper a:hover,
.right-sidebar-wrapper a:focus,
.right-sidebar-wrapper a:active {
  color: #000000 !important;
  text-decoration: none !important;
}

.right-sidebar-wrapper .profile-bot {
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.right-sidebar-wrapper .profile-bot-description {
  padding-left: 10px;
  padding-right: 10px;
}

.right-sidebar-wrapper .profile-bot .bot-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.right-sidebar-wrapper .profile-bot .bot-text {
  padding-left: 10px;
  font-size: 14px;
}

/* Share post page */
.instaButton {
  background-color: rgba(245, 96, 64, 0.7) !important;
  border: none;
  padding: 0px;
  font-size: 23px !important;
  color: white !important;
  cursor: pointer;
  min-width: 34px;
}

/* Surveys */
.survey-textbox,
.survey-textbox:focus,
.survey-textbox:active {
  width: 100%;
  background-color: #eee;
  border: 3px solid #fff;
  border-radius: 0.4em;
  outline: none;
  resize: none;
  padding: 10px;
}

.survey-question {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.survey-input {
  margin: 0;
  border: 0px !important;
  border-bottom: 1px solid #aaa !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
  font-size: 14px !important;
}

.survey-input:focus,
.survey-input:active,
.select:focus,
.select:active {
  box-shadow: none !important;
  border-bottom: 2px solid #dc3545 !important;
}

/* Modal component */
.modal .modal-dialog {
  max-width: 100% !important;
}

.modal-subtitle {
  font-size: 14px;
}

/* Form Fields Component */
.form-check-label {
  /* font-size: 14px !important; */
}

.select {
  border: 0px !important;
  border-bottom: 1px solid #aaa !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  padding: 0px !important;
  margin: 0px !important;
}

/* Search component */
.card-body {
  /* padding: 10px 10px 0px 10px !important; */
}

.card-title {
  font-size: 15px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

/* .profile-card-img {
  position: relative;
  color: #fff;
}

.profile_card_img img {
  object-fit: contain;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  transition: all 0.3s;
}

.profile-card-img .img-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.profile_card_img h6 a {
  color: #000;
  text-decoration: none;
}

.profile_card_big_img {
  width: 100%;
  z-index: 99;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: none;
  background-color: rgba(0,0,0,0.9);
  transition: all 0.3s;
}

.profile_card_big_img img {
  width: 100% !important;
  height: 125px !important;
  object-fit: contain !important;
  transition: all 0.3s !important;
  transform: scale(1, 1) !important;
}

.profile_card_big_img img:active,
.profile_card_big_img img:focus {
  width: 100% !important;
  height: 125px !important;
  object-fit: contain !important;
} */

.btn_follow small {
  padding: 1px 7px !important;
}

.btn_send_text {
  font-size: medium;
  padding: 2.5px 7px !important;
}

.nav-pills .nav-link {
  color: #6c757d !important;
  font-size: small;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #6c757d !important;
  border-bottom: 2px solid !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}

/* Payments Component */
.subscription-plan-card {
  border: 2px solid #dc3545 !important;
  border-radius: 5px !important;
}

.subscription-plan-card .card-header {
  border-radius: 0px !important;
}

.subscription-plan-card body {
  padding: 10px !important;
}

.subscription-plan-card body .small {
  font-size: 10px !important;
}

.payment-card-list {
  list-style-type: none;
  font-size: 13px;
  padding-left: 0px;
}

.payment-card-list li:before {
  content: "\2713";
  padding-right: 10px;
}

.payment-card-list li {
  border-bottom: 2px dotted #aaa;
  padding: 5px 10px 5px 15px;
}

.payment-card-list li:last-child {
  border-bottom: none;
  padding: 5px 10px 0px 15px;
}

/* Chatbot */
.chatbot {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #dc3545;
  color: #fff;
}

.chatbot i {
  font-size: 22px;
  position: relative;
  left: 27%;
}

.sidebar {
  overflow-y: scroll;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.login-page-clipart {
  font-family: "Poppins" !important;
  text-align: center;
}

.login-page-clipart img {
  transform: scale(1.3);
}

.login-page-clipart .login-page-caption {
  position: relative;
  top: -10px;
  font-size: xx-large;
  font-weight: 800;
}

/* Mobile devices */
.mob-navbar-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: initial;
}

/* Media queries */
@media (max-height: 569px) {
}

@media (max-width: 575px) {
  audio {
    width: 85vw !important;
  }

  .font-xs-small {
    font-size: small !important;
  }

  .login-page-clipart .login-page-caption {
    font-size: large;
  }

  .bot-chat-window-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: white;
  }
}

@media (min-width: 576px) {
  audio {
    width: 80vw !important;
  }

  .fs-sm-12 {
    font-size: 12px !important;
  }

  .fs-sm-14 {
    font-size: 14px !important;
  }

  .fs-sm-18 {
    font-size: 18px !important;
  }

  .fs-sm-22 {
    font-size: 22px !important;
  }
}

@media (min-width: 768px) {
  .App {
    padding: 50px;
  }

  .modal .modal-dialog {
    max-width: 80% !important;
  }

  audio {
    width: 25vw !important;
  }

  .btn-submit {
    min-width: 12vw !important;
    min-height: 6vh !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
