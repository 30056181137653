.numbers-wrapper {
  background-color: var(--color-black-400);
  text-align: center;
  border-radius: var(--spacing-40);
}

.numbers-wrapper .number-card {
  width: var(--spacing-128);
  height: var(--spacing-128);
  color: var(--color-black-400);
  background-color: var(--color-white);
  border: 0px solid var(--color-violet-400);
  border-radius: 50%;
  text-align: center;
  /* padding-top: var(--spacing-32); */
}
